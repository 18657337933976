import React, { Component } from 'react';
import _ from "lodash";

export class NewForm extends Component {

  constructor(props) {
    super(props);
    this.state = {...this.keyPair(), groupedData: groupedData(props.options)};
    this.submit = this.submit.bind(this);
  }

  validate(stateSet =  false) {
    const { options } = this.props;
    let isErrorPresent = false;
    options && _.map(options, (value, key) => {
      let fieldValue = this.state[value.name];
      if (!fieldValue || fieldValue.length < 1 || (fieldValue && value.pattern && !fieldValue.match(value.pattern))) {
        if(stateSet){
          this.setState({[`${value.name}Error`]: `Please enter ${key}`})
        }
        isErrorPresent = true;
      }
    });
    return !isErrorPresent;
  }

  async submit(e) {
    e.preventDefault();
    const { onSubmit } = this.props;
    if (this.validate(true)) {
      onSubmit(this.keyPair(false));
    }
  }

  keyPair = (defaultValue = true) =>{
    const { options } = this.props;
    let params = {};
      options && _.map(options, (value, key) => {
          params[value.name] = defaultValue ? (value.value ?? "") : this.state[value.name];
      });
      return params;
  }

  handleChange(event,option) {
    if(option && option.pattern){
      if(event.target.value === "" || event.target.value.match(option.pattern)){
        this.setState({ [event.target.name]: event.target.value, [`${event.target.name}Error`]: '' });
      }
    } else {
      this.setState({ [event.target.name]: event.target.value, [`${event.target.name}Error`]: '' });
    }
  }

  render() {
    const { groupedData } = this.state;
    return (
      <div className="p-0 pl-3 pr-3 mt-3">
        <form className="addUser_form pl-1 pt-1" onSubmit={this.submit}>
          <div className="form-row">
            {
              groupedData && _.map(groupedData, (value, key) => {
                if(value.is_grouped){
                  return(
                      <div className={`form-row w-100 row`} key={key}>{
                        value && _.map(value['data'], (value, key) => {
                          return (
                              <div className={`form-col col s6 ml-1 mr-1`} key={key}>
                                <LabelInput {...value}
                                            title={key}
                                            value={this.state[value.name]}
                                            onChange={(e) => (this.handleChange(e, value))}
                                            onFocus={(e) => (e.target.value === "0" ? this.setState({[e.target.name]: ""}) : null)}
                                            onBlur={(e) => (e.target.value === "" ? this.setState({[e.target.name]: "0"}) : null)}
                                 />
                              </div>
                          );
                        })
                      }
                      </div>
                  )
                } else {
                  return(
                      <div className={`form-row w-100 row`} key={key}>
                        <div className={`form-col col s12 ml-1 mr-1`}>
                          <LabelInput {...value}
                                      title={key}
                                      value={this.state[value.name]}
                                      onChange={(e) => (this.handleChange(e, value))}/>
                        </div>
                      </div>
                  );
                }
              })
            }
          </div>
          <div className="form-row w-100">
            <div className={`form-col col s12 ml-1 mr-1`}>
              <div className="form-group btn-grup w-100 text-right">
                <button type={"button"}
                        className={"btn btn-default btn-blank modal-action modal-close"}
                        onClick={()=> this.props.onCancel()}>
                  <span className="btn_txt btn btn-default">Cancel</span>
                </button>
                <button type={"submit"}
                        className={"btn btn-default btn-primary"}
                        disabled={!this.validate()}>
                  <span className="btn_txt">Submit</span>
                  <i className="ico ico_tick_sml"/>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}


function symbolPrevent(e,v){
  return ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault();
}
const LabelInput = (props) =>{
  const title = props['title'];
  return(
      <div className={`form-group w-100`} key={title}>
        <label className="mb-0"><b>{title}</b><span className="rqrd_str">*</span></label>
        <input {...props}
               onKeyDown={(e)=> props["type"] === "number" && symbolPrevent(e)}
               className={"form-control"}/>
      </div>
  )
}

function groupedData(options = {}){
  let data = {};
  options && _.map(options, (value, key) => {
    if(value['group']){
      let keyName = value['group'];
      delete value['group'];
      if(Object.keys(data).includes(keyName)){
        data[keyName] = {...data[keyName], data: { ...data[keyName]['data'], [key]: value}}
      } else {
        data[keyName] = {data: {[key]: value}, is_grouped: true};
      }
    } else {
      data[key] = value;
    }
  });
  return data;
}