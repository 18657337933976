import React from "react";
import _ from 'lodash'

export class PaginationFooter extends React.Component {
    render() {
        const { totalRecords, limit, pageNo, getPageData } = this.props
        let getReminder = totalRecords % limit;
        let totalPages = 0;
        if (getReminder > 0) {
            totalPages = ((totalRecords - getReminder) / limit) + 1;
        } else {
            totalPages = totalRecords / limit;
        }
        let pagesCount = [];
        if(totalPages < 1){
            return null;
        }
        if (totalPages > 0 && totalPages < 8) {

            for (let ii = 1; ii <= totalPages; ii++) {
                pagesCount.push(
                    <li key={ii}
                        onClick={() => getPageData(ii)}
                        className={pageNo === ii ? "page-item active" : "page-item"}>
                        <div className="page-link">{ii}</div>
                    </li>
                );
            }
        } else {
            /*********Pagination with dots for more than 7 pages**********/
            let current = pageNo, last = totalPages, ranges = [];
            for (let i = 1; i <= totalPages; i++) {
                if((last - 5 < i && current >= i) || (pageNo <= 5 && i <= 5 && pageNo - 2 <= i) ||  (current + 2 >= i && current - 2 <= i) || i === last){
                  ranges.push(i);
                } else if(!ranges.includes("...") && ranges.length > 1){
                    ranges.push("...");
                }
            }
            /*********End of Pagination with dots for more than 7 pages**********/

            // Parsed pages to show on UI as required.
            _.map(ranges, (pageVal, pageKey) => {
                pagesCount.push(
                    pageVal === '...' ?
                        <li
                            key={pageKey}
                            className={pageNo === pageVal ? "page-item active" : "page-item"}>
                            <div className="page-link">{pageVal}</div>
                        </li> :
                        <li
                            key={pageKey}
                            onClick={() => getPageData(pageVal)}
                            className={pageNo === pageVal ? "page-item active" : "page-item"}>
                            <div className="page-link">{pageVal}</div>
                        </li>
                );
            })
        }


        let pagesCountHtml = _.map(pagesCount, (html) => html)

        const toRecords = totalRecords > pageNo * limit ? pageNo * limit : totalRecords;
        const fromRecords = (pageNo * limit) - (limit - 1);

        return (
            <div className="appCus_pagination d-inline-flex w-100">
            <div className="d-sm-flex justify-content-between align-items-center mt-4 mb-2">
                <div className="visible-entries">Showing {fromRecords} to {toRecords} of {totalRecords} entries</div>
                <div className="pages">
                    <nav aria-label="...">
                        <ul className="pagination mb-0">
                            <li onClick={() => pageNo > 1 ? getPageData(1) : ''} className={pageNo > 1 ? "page-item" : "page-item disabled"}><span className="page-link"><i className="fa fa-first-page"/></span></li>
                            <li onClick={() => pageNo > 1 ? getPageData(pageNo - 1) : ''} className={pageNo > 1 ? "page-item" : "page-item disabled"}><span className="page-link"><i className="fa fa-angle-left"/></span></li>
                            {pagesCountHtml}
                            <li onClick={() => (pageNo < totalPages) ? getPageData(pageNo + 1) : ''} className={(pageNo < totalPages) ? "page-item" : "page-item disabled"}><span className="page-link"><i className="fa fa-angle-right"/></span></li>
                            <li onClick={() => (pageNo < totalPages) ? getPageData(totalPages) : ''} className={(pageNo < totalPages) ? "page-item" : "page-item disabled"}><span className="page-link"><i className="fa fa-last-page"/></span></li>
                        </ul>
                    </nav>
                </div>
            </div>
            </div>
        );
    }
}