import React, { Component } from 'react';
import { connect } from 'react-redux';
import SATList from './SATList';
import * as satActions from '../../../modules/sat/action';
import './HomeSATs.scss';
import * as temporaryActions from "../../../modules/storeTemporary/action";
import {NewTopicButton, ShowDragHandleButton} from "../../helper/commonMethods";
import { withRouter, Prompt } from "react-router-dom";
import * as userSatActions from "../../../modules/userSat/action";
import * as userSatTestProgressActions from "../../../modules/userSatTestProgress/action";
import * as userProgressActions from "../../../modules/userProgress/action";
import * as assignmentActions from "../../../modules/assignment/action";
import * as dialogActions from "../../../modules/dialog/action";
import {FORM_DIALOG_BOX} from "../../dialogs/dialogs";

const mapStateToProps = state => {
    return ({
        sats: state.sats,
        account: state.account,
        satsUser: state.temporaryData.satsUser
    })
}

const mapDispatchToProps = dispatch => {
    return ({
        getSATs: () => dispatch(satActions.getCurrentUserSats({ isPublish: true })),
        createSAT: (attributes) => dispatch(satActions.createRealSat(attributes)),
        openDialog: (config, options) => dispatch(dialogActions.open(config, options)),
        getUserSats: () => dispatch(userSatActions.getAllUserSat()),
        getUserSatsScores: () => dispatch(userSatActions.getAllSatsScore()),
        getUserSatTestScores: () => dispatch(userSatTestProgressActions.getUserAllSatTestScores()),
        getAllAssignments: () => dispatch(assignmentActions.getAllAssignments()),
        getUserSatsProgress: () => dispatch(userProgressActions.getUserSatsProgress()),
        setSatsUserData: (sats) => dispatch(temporaryActions.setSatsUserData(sats)),
        clearSatsUserData: () => dispatch(temporaryActions.clearSatsUserData()),
        updateUserSatsPositions: (sats) => dispatch(satActions.updateUserSatsPositions(sats)),
    })
}

class HomeSATs extends Component {

    constructor(props) {
        super(props)
        this.state = {
            sats: [],
            showDragHandle: false,
            loading: false,
            verbal: 0,
            math: 0
        }
    }

    async componentDidMount() {
        window.scrollTo(0,0);
        const {
            getSATs, getUserSats, getUserSatsScores, clearSatsUserData, getUserSatTestScores, getUserSatsProgress,
            getAllAssignments
        } = this.props;
        await clearSatsUserData();
        await getSATs();
        await getUserSats();
        await getUserSatsScores();
        await getUserSatTestScores();
        await getUserSatsProgress();
        await getAllAssignments();
    }

    componentDidUpdate(props) {
        const { sats, satsUser} = this.props;
        if (satsUser.length > 0 && satsUser !== props.satsUser) {
            this.setState({ sats: satsUser });
        } else if (sats !== props.sats) {
            this.setState({ sats });
        }
    }

    setReorderData = async (satsUser) =>{
        const { sats, updateUserSatsPositions, clearSatsUserData } = this.props
        await this.setState({loading: true});
        if(satsUser !== sats){
          await updateUserSatsPositions({sats: satsUser});
        }
        const { showDragHandle } = this.state;
        if(showDragHandle){
            window.removeEventListener("beforeunload", this.handleOnBeforeUnload)
        } else {
            window.addEventListener("beforeunload", this.handleOnBeforeUnload)
        }
        await clearSatsUserData();
        await this.setState({showDragHandle: !showDragHandle, loading: false});
    }

    handleOnBeforeUnload = e => {
        e.preventDefault()
        e.returnValue = "";
    }

    createNewSat = () =>{
        const { createSAT, openDialog } = this.props;
        const numberInput = {type: "number", pattern: /^[1-9][0-9]?$|^[1-7][0-9][0-9]?$|^800?$|^0$/}
        openDialog(FORM_DIALOG_BOX, {
            title: ()=> "Create New SAT",
            options: {
                "Title": {type: "text", name: "title", value: ""},
                "Verbal": {...numberInput, name: "verbal", value: "0", group: "group1"},
                "Math": {...numberInput, name: "math",  value: "0", group: "group1"},
            },
            createAction: (params) => createSAT(this.parseParams(params))
        });
    }

    parseParams = ({title, verbal, math}) =>{
        return({title, marks: {verbal: parseInt(verbal), math: parseInt(math)} })
    }

    render() {
        const { sats, showDragHandle, loading } = this.state;
        const { account, setSatsUserData } = this.props;
        return (
            <div className="row">
                <Prompt when={showDragHandle || loading}
                    message="Are you sure you want to leave Page without submit new order?"/>
                <div className="content-wrapper-before gradient-45deg-indigo-purple"/>
                <div className="col s12">
                    <div className="container">
                        <div className="dash_top_section">
                            <div className="dash_header d-flex flex-wrap align-items-center mb-2">
                                <h2 className="dash_title mr-auto w-auto my-2">SATs</h2>
                                <NewTopicButton disabled={loading || showDragHandle}
                                                show={!account.tutor_login}
                                                topicType={'SAT'}
                                                onCLick={()=> this.createNewSat()} />
                                <ShowDragHandleButton value={showDragHandle}
                                                      disabled={loading}
                                                      type={'SAT'}
                                                      show={!account.tutor_login && sats.length > 1}
                                                      onCLick={()=> this.setReorderData(sats)} />
                            </div>
                            <div className="row vertical-modern-dashboard">
                                <div className="col s12 satAct_content_sec">
                                    <SATList sats={sats}
                                             loading={loading}
                                             showDragHandle={showDragHandle}
                                             updateUserSatsPositions={setSatsUserData}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomeSATs));
