import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SVG from '../../SVG';
import * as problemSetActions from '../../../modules/problemSet/action';
import * as satQuestionActions from '../../../modules/satQuestion/action';
import AdminProblemSets from '../../admin/content/topics/problemSets/AdminProblemsets';
import { CREATE_PROBLEM_SET_DIALOG_BOX, MATH_SECTION_DIALOG_BOX } from "../dialogs";
import * as dialogActions from '../../../modules/dialog/action';
import _ from 'lodash'
import AssignButton from '../../home/AssignButton';
import * as questionActions from "../../../modules/question/action";
import * as problemSetQuestionActions from "../../../modules/problemSetQuestion/action";
import * as alertActions from "../../../modules/alert/action";
import {IN_A_ROW_CORRECT_COUNT} from "../../../constants";
import {getProblemSetProgress, getUserProgressAndProgressQuestion} from "../../helper/commonMethods";
import {
  AddVideoExplanation,
  PublishSequenceButton,
  ResetSequenceBtn,
  RestartSequenceBtn,
  SequenceProgressBar,
  VideoExplanation
} from "../container/DialogCommonMethods";
import AssignMultipleProblemSet from "../../home/AssignMultipleProblemSet";
import * as userProgressActions from "../../../modules/userProgress/action";

class SatSectionModal extends Component {

  constructor(props) {
    super(props)
    const {question} = props;
    this.state = {
      formActive: false,
      question: this.props.question,
      topic: question?.topic?.title ?? '',
      showCreate: false,
      title: '',
      showVideoExplanation: false,
      editorState: question?.video_explanation ?? null,
      showVideoMockExplanation: false,
      editorMockState: question?.video_mock_explanation ?? null
    }
    this.referenceType = 'HomeSatQuestionTopic';
    this.updateEditorState = this.updateEditorState.bind(this);
    this.updateMockEditorState = this.updateMockEditorState.bind(this);
  }

  async componentDidMount() {
    const { question } = this.state;
    const { getSatProblemSets, account, location, getUserProgress, getAllUserProblemsetsProgress} = this.props;

    if (location.pathname.includes("home")) {
      await getUserProgress({ reference_id: question.id, reference_type: this.referenceType, user_id: account.id });
      await getAllUserProblemsetsProgress({ sat_question_id: question.id, reference_type: 'HomeProblemSetQuestions', user_id: account.id });
    }
    await getSatProblemSets({ sat_question_id: question.id });
  }

  handler = (e) => {
    this.setState({ topic: e.label });
    this.props.handler(e);
  }

  updateQuestion = (event) => {
    const { question, topic } = this.state;
    event.preventDefault();
    question.topic.title = topic;
    this.setState({ formActive: false, question });
    this.props.updateQuestion(event);
  }

  toggleForm = () => {
    const { formActive } = this.state
    this.setState({ formActive: !formActive })
  }

  goToSubtopic = () => {
    const { question } = this.state;
    const { location, isMock } = this.props;
    if (location.pathname.includes("home")) {
      this.props.history.push(`/home/sat/${question.id}${isMock ? `?mock=${isMock}` : ''}`);
    } else {
      this.props.history.push(`/admin/sat/${question.id}`);
    }
    this.props.close();
  }

  toggle = () => {
    const { showCreate } = this.state;
    this.setState({ showCreate: !showCreate });
  }

  handleChange = (prop) => {
    return ({ target }) => this.setState({ [prop]: target.value.trimLeft() });
  }

  createProblemSet = async (e) => {
    e.preventDefault();
    const { createProblemSet, problemsets } = this.props;
    const { question, title } = this.state;
    const problemSetsArr = problemsets[question.id];
    let data = {};
    data.sat_question_id = [question.id]
    data.sat_position = [{ sat_question_id: question.id, value: problemSetsArr?.length ?? 0 }];
    data.title = title;
    await createProblemSet(data)
    this.setState({ title: "" });
  }
  
  openMathsSection = () => {
    const { openDialog, question, updateAfterPublishUnpublish } = this.props;
    const { allmathsTopic } = this.state;
    openDialog(MATH_SECTION_DIALOG_BOX, {
      allmathsTopic,
      satTopic: question,
      updateAfterPublishUnpublish: (q, b) => updateAfterPublishUnpublish(q, b)
    });
  }

  addFromBucket = () => {
    const { openDialog, problemsets, updateAfterPublishUnpublish } = this.props;
    const { question } = this.state;
    openDialog(CREATE_PROBLEM_SET_DIALOG_BOX, {
      problemSetsList: problemsets[question.id],
      question: question,
      typeOf: 'SAT',
      updateAfterPublishUnpublish: (q, b) => updateAfterPublishUnpublish(q, b)
    });
  }

  pubishUnPublishSatQuestionSequence = async () => {
    const { question, pubishOrUnpublishSatQuestionSequence, updateAfterPublishUnpublish } = this.props;
    if (question.id) {
      await pubishOrUnpublishSatQuestionSequence({ id: question.id, isPublish: !question.isPublish });
      await updateAfterPublishUnpublish(question);
      const message = `'${question.title}' Sequence ${!question.isPublish ? 'Published' : 'Unpublished'}`;
      this.flashMessage({message, type: 'success'})
    } else {
      alert("Error: No SAT Question id selected!!");
    }
  }

  resetProgress = async () => {
    const { account, afterReset, resetUserProgress } = this.props;
    const { question } = this.state;
    let res = window.confirm("Are you sure, you want to Reset your Progress for this Sequence?");
    if (res) {
      await resetUserProgress({ reference_id: question.id, reference_type: this.referenceType, user_id: account.id });
      this.flashMessage({message: "Sequence Progress Reset", type: 'success'});
      afterReset();
    } else {
      return null;
    }
  }

  restartSequence = async () => {
    const { account, history, location, resetUserProgress, close } = this.props;
    const { question } = this.state;
    let res = window.confirm("Are you sure you want to restart the sequence? Your progress will be reset as well.");
    if (res) {
      await resetUserProgress({ reference_id: question.id, reference_type: this.referenceType, user_id: account.id });
      this.flashMessage({message: "Sequence Restart", type: 'success'});
      close();
      history.push(`/${location.pathname.includes("home") ? 'home': 'admin'}/sat/${question.id}`);
    } else {
      return null;
    }
  }

  showHideAddVideoSection = () => {
    this.setState({ showVideoExplanation: !this.state.showVideoExplanation });
  }

  updateEditorState(editorState) {
    this.setState({ editorState });
  }

  submitAddVideExplanation = async (type) => {
    const { editorState, question, editorMockState } = this.state;
    const { updateSatQuestionVideo, updateAfterPublishUnpublish, close } = this.props;
    if (type === "Real") {
      await updateSatQuestionVideo({ id: question.id, video_explanation: editorState, type });
    } else if (type === "Mock") {
      await updateSatQuestionVideo({ id: question.id, video_mock_explanation: editorMockState, type });
    }
    close()
    updateAfterPublishUnpublish(question);
  }

  showHideAddVideoMockSection = () => {
    this.setState({ showVideoMockExplanation: !this.state.showVideoMockExplanation });
  }

  updateMockEditorState(editorMockState) {
    this.setState({ editorMockState });
  }

  checkSequenceSubmit = async () =>{
    const { getQuestions, getProblemSetQuestions, account } = this.props;
    const insideProblemSet = (_.has(account, 'progress.next_problemset_id') && account?.progress?.next_problemset_id);
    if (insideProblemSet) {
      const {question} = this.state;
      await getQuestions({ params: { sat_question_id: question.id }, order_by: { position: 'asc' } });
      await getProblemSetQuestions({ problem_set_id: account.progress.next_problemset_id });
      const { problemset_questions, questions } = this.props;
      if(problemset_questions.length > 0 && questions?.length > 0){
        const {userProgress, userProgressQuestion} = getUserProgressAndProgressQuestion({account,questions});
        if(userProgress && (userProgressQuestion === '' || !userProgressQuestion)) {
          let { problemSetCorrectAnswers } = getProblemSetProgress({account, problemset_questions});
          if(problemSetCorrectAnswers >= IN_A_ROW_CORRECT_COUNT){
            this.flashMessage({message: `Sequence already completed`, type: 'success'});
            return this.sequenceSubmit();
          }
        }
      }
    }
    return this.goToSubtopic();
  }

  sequenceSubmit = async () => {
    const { saveUserProgress, account, satQuestions, questions, actQuestions } = this.props;
    const { question } = this.state;
    const userProgress = {
      user_id: account.id,
      reference_type: this.referenceType,
      reference_id: question.id,
      question_id: null,
      total_questions: questions.length,
      questions_complete: questions.length,
      started: false,
      percent_complete: 100,
      sat_id: satQuestions?.sat_id,
      time_spent_after_answer: account?.progress?.time_spent_after_answer ?? 0,
      act_id: actQuestions?.act_id,
    }
    await saveUserProgress(userProgress);
  }

  flashMessage = (payload, timeout = 5000) => {
    const {alertPush, alertDelete} = this.props;
    alertPush(payload);
    setTimeout(() => alertDelete(),timeout);
  }

  render() {
    const {
      close, problemsets, updateProblemSetSatPosition, account, location, sat, isMock, updateAfterPublishUnpublish,
      openDialogAfterAssignAssignment, openDialog, assignments
    } = this.props;
    const { showCreate, title, question, showVideoExplanation, showVideoMockExplanation } = this.state;
    const setPercent = setPercentage(account);
    const questionPublish = !!this.props.question?.isPublish;

    return (
      <div className="admin-problemSet-popup">
        {
          location.pathname.includes("home") &&
              <div className="row">
                {
                    (questionPublish === true || question.video_explanation || question.video_mock_explanation) &&
                    <div className="col-12 col-md-5 leftSideOfPopupCls">
                      {/* <h1>{question.title}</h1> */}
                      {
                          questionPublish === true &&
                          <div className="master_hdr d-inline-flex flex-wrap align-items-center justify-content-center w-100">
                            <div className="titleWrap d-flex flex-wrap align-items-center w-100">
                              <h5 className="masterTitleCls d-inline-block mr-5">Master it all:</h5>
                              <div className="contentRight d-inline-flex align-items-center btnGroup mb-0">
                                <SequenceButton question={this.props.question}
                                                location={location}
                                                account={account}
                                                onClick={this.checkSequenceSubmit}/>
                                <AssignButton referenceType="sat_question"
                                              reference_type={this.referenceType}
                                              reassign={account.tutor_login && (account?.progress?.percent_complete >= 100) && (assignments?.filter(assignment => (assignment.assignmentable_id === question.id &&  assignment.status === "completed")).length > 0)}
                                              show={account?.tutor_login && !(assignments.map((assignment) => assignment.assignmentable_id).includes(question.id))}
                                              referenceId={question.id}
                                              topicName="sat_question"
                                              topicId={question.id}
                                              account={account}
                                              openDialog={openDialog}
                                              openDialogAfterAssignAssignment={openDialogAfterAssignAssignment}/>
                                <RestartSequenceBtn account={account} onClick={this.restartSequence}/>
                                <ResetSequenceBtn account={account} onClick={this.resetProgress}/>
                              </div>
                            </div>
                            <div className="progressAndResetDivCls w-100 d-flex my-4">
                              <SequenceProgressBar account={account} percentage={setPercent}/>
                            </div>
                          </div>
                      }

                      <VideoExplanation video_mock_explanation={question.video_mock_explanation}
                                        video_explanation={question.video_explanation}
                                        questionPublish={questionPublish}
                                        isMock={isMock} />
                    </div>
                }

                <div className={`col-12 col-md-7 rightSideOfPopupCls mt-2 ${(questionPublish === true || question.video_explanation || question.video_mock_explanation) ? 'side-problem-set-border' : ''} `}>
                      <AssignMultipleProblemSet problemsets={problemsets[question.id]}
                                                close={close}
                                                sat={sat}
                                                updateProblemSetSatPosition={updateProblemSetSatPosition}
                                                topicType="SatQuestionTopic"
                                                selectedId={question.id}
                                                openDialog={openDialog}
                                                getProblemSetProgress={() => this.getProblemSetProgress()}
                                                openDialogAfterAssignAssignment={openDialogAfterAssignAssignment}/>
                </div>
              </div>
        }

        {
          location.pathname.includes("admin") &&
            <>
              <div className="addVideoCls btnGroup flex-wrap align-items-center mb-5">
                <SequenceButton question={this.props.question}
                                location={location}
                                account={account}
                                onClick={this.goToSubtopic}/>
                <span>
                  <PublishSequenceButton account={account}
                                         publish={questionPublish}
                                         className={'pubUnpubAdminCls'}
                                         onClick={() => this.pubishUnPublishSatQuestionSequence()}/>
                </span>

                <AddVideoExplanation videoExplanation={question.video_explanation}
                                     showExplanation={!!showVideoExplanation}
                                     title={"Video Explanations:"}
                                     showHideVideoSection={() => this.showHideAddVideoSection()}
                                     submitAddVideExplanation={()=> this.submitAddVideExplanation("Real")}
                                     updateEditorState={this.updateEditorState}/>

                <AddVideoExplanation videoExplanation={question.video_mock_explanation}
                                     showExplanation={!!showVideoMockExplanation}
                                     title={"Video Explanations for Mock:"}
                                     showHideVideoSection={() => this.showHideAddVideoMockSection()}
                                     submitAddVideExplanation={()=> this.submitAddVideExplanation("Mock")}
                                     updateEditorState={this.updateMockEditorState}/>
              </div>
              <div className="linked_Sections">
                {<h3 className="title">Problem sets:</h3>}
                {/* {<h3 className="title">Linked Topics:</h3>} */}
                {/* <p className="sec_txt">{question?.topic?.title}</p> */}
                {/* {!formActive && <button onClick={() => this.toggleForm()}>Edit</button>} */}
                {/* { this.renderQuestion()} */}
                <AdminProblemSets
                    problemsets={problemsets[question.id]}
                    updateAfterPublishUnpublish={updateAfterPublishUnpublish}
                    question={question}
                    close={close}
                    updateProblemSetSatPosition={updateProblemSetSatPosition}
                    topicType="SatQuestionTopic"
                    selectedId={question.id}
                    sat={sat}
                />
              </div>
            </>
        }

        {
          !(account.userType === "Student" || (location.pathname).includes('home')) &&
            <>
              {
                !showCreate ?
                    <div className="form-container">
                      <div className="subtopic-form" onClick={() => this.toggle('showCreate')}>
                        <SVG name="plus"/>
                        <p className="mb-0">Problem Sets</p>
                      </div>
                    </div>
                    :
                    <div className="add-topic w-100 position-relative">
                      <div className="form_wrap d-flex align-items-center justify-content-start w-100 position-relative">
                        <form onSubmit={this.createProblemSet}>
                          <input
                              type="text"
                              value={title}
                              onChange={this.handleChange('title')}/>
                          <button className="btn btn-primary" type="submit" disabled={title === ""}>Create Problem Set</button>
                        </form>
                        <button className="btn btn-danger" onClick={() => this.toggle('showCreate')}>Cancel</button>
                      </div>
                    </div>
              }
              <div className="form-container">
                <div>
                  <div className="subtopic-form" onClick={this.addFromBucket}>
                    <SVG name="plus" />
                    <p className="mb-0">Add Problem Set From Bucket</p>
                  </div>
                </div>
              </div>
              <div className="form-container">
                <div>
                  <div className="subtopic-form" onClick={this.openMathsSection}>
                    <SVG name="plus" />
                    <p className="mb-0">Add Existing Problem Set</p>
                  </div>
                </div>
              </div>
            </>
        }
      </div>
    )
  }
}

const setPercentage = (account) =>{
  if (account?.progress?.percent_complete) {
    return account.progress.percent_complete >= 100 ? 100 : account.progress.percent_complete
  }
  return '';
}

const SequenceButton = ({location, account, question, onClick}) =>{
  let isHomeLocation = location.pathname.includes("home");
  return(
      <span className={isHomeLocation ? "sequenceHomeBtn my-2 d-flex p-0" : "sequenceBtn"}>
        {
          (isHomeLocation && account?.progress?.percent_complete >= 100) ?
              <button className="btn btn-primary" disabled={true}>Sequence</button>
              :
              <>
                { !(isHomeLocation && question.isPublish === false) &&
                  <button className="btn btn-primary" disabled={!!account.tutor_login} onClick={onClick}>
                    Sequence
                  </button>
                }
              </>
        }
      </span>
  )
}

const mapStateToProps = (state) => {
  const selectTopics = state?.satTopics?.map(topic => {
    return {
      value: topic.id,
      label: topic.title
    }
  })
  return ({
    selectTopics,
    account: state.account,
    assignments: state.assignments,
    problemsets: state.problemsets,
    satQuestions: state.satQuestions,
    questions: state.questions,
    problemset_questions: state.problemset_questions,
  })
}

const mapDispatchToProps = dispatch => {
  return ({
    getSatProblemSets: (params) => dispatch(problemSetActions.getSatProblemSets(params)),
    createProblemSet: (params) => dispatch(problemSetActions.createProblemSet(params)),
    updateProblemSetSatPosition: (problemSets) => dispatch(problemSetActions.updateProblemSetSatPosition(problemSets)),
    openDialog: (config, options) => dispatch(dialogActions.open(config, options)),
    pubishOrUnpublishSatQuestionSequence: (data) => dispatch(satQuestionActions.publishUnPublishSatQuestion(data)),
    getUserProgress: (params) => dispatch(userProgressActions.getUserProgress(params)),
    resetUserProgress: (params) => dispatch(userProgressActions.resetUserProgress(params)),
    getAllUserProblemsetsProgress: (params) => dispatch(userProgressActions.getAllUserProblemSetsProgress(params)),
    saveUserProgress: (params) => dispatch(userProgressActions.saveUserProgress(params)),
    updateSatQuestionVideo: (data) => dispatch(satQuestionActions.updateSatQuestionVideo(data)),
    getQuestions: (params) => dispatch(questionActions.getAllQuestions(params)),
    getProblemSetQuestions: (params) => dispatch(problemSetQuestionActions.getAllProblemSetQuestions(params)),
    alertPush: (payload) => dispatch(alertActions.alertPush(payload)),
    alertDelete: () => dispatch(alertActions.alertDelete())
  })
}

export const SatSectionDialog = withRouter(connect(mapStateToProps, mapDispatchToProps)(SatSectionModal));

