import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as reportedProblemActions from '../../../modules/reportedProblem/action'
import * as alertActions from "../../../modules/alert/action";

class ReportProblemModal extends Component {
  state = { problem_type: [], comment: '' }

  handleProblemTypeChange = (e) => {
    
    const {problem_type} = this.state
    const { name, value, checked } = e.target;
    if (checked){
      this.setState({ [name]: [...problem_type, value] })
    }else{
      this.setState({ [name]: problem_type.filter(item => item !== value) })
    }
  }

  handleCommentChange = (e) => {
    this.setState({ comment: e.target.value });
  }

  submit = async () => {
    const { problem_type, comment } = this.state;
    const { submitReportProblem, closeDialog, question } = this.props;
    await submitReportProblem({ problem_type, comment, question_id: question.id });
    await closeDialog();
  }

  flashMessage = (payload, timeout = 5000) => {
    const {alertPush, alertDelete} = this.props;
    alertPush(payload);
    setTimeout(() => alertDelete(),timeout);
  }

  render() {
    const { problem_type, comment } = this.state;
    return (
      <div className="report-problem">
        <div className="problem_type">
          <p>If you believe the answer is wrong, please read through the answer explanation before submitting the error report.</p>
          <p><b>What is Wrong?</b></p>
          <input type="checkbox" value="The answer is wrong" name="problem_type" onChange={this.handleProblemTypeChange} style={{ position: "relative", pointerEvents: "auto", opacity: 1 }} /> <label>The answer is wrong.</label><br />
          <input type="checkbox" value="There is a typo" name="problem_type" onChange={this.handleProblemTypeChange} style={{ position: "relative", pointerEvents: "auto", opacity: 1 }} /> <label>There is a typo. </label><br />
          <input type="checkbox" value="The answer explanation is unclear" name="problem_type" onChange={this.handleProblemTypeChange} style={{ position: "relative", pointerEvents: "auto", opacity: 1 }} /> <label>The answer explanation is unclear. </label><br />
          <input type="checkbox" value="Something isn't working/something seems broken" name="problem_type" onChange={this.handleProblemTypeChange} style={{ position: "relative", pointerEvents: "auto", opacity: 1 }} /> <label>Something isn't working/something seems broken. </label><br />
        </div>
        <div className="comment_section mt-2">
          <p><b>Description of issue</b></p>
          <textarea rows="4" onChange={this.handleCommentChange} value={comment}/>
        </div>
        <div className="submit_section">
          <button type="submit" className="btn btn-default btn-primary" disabled={problem_type.length === 0} onClick={this.submit}>
          <span className="btntxt">Submit Issue</span> 
          </button>
        </div>
      </div>
    );
  }
}


const mapDispatchToProps = (dispatch) => {
  return ({
    submitReportProblem: (params) => dispatch(reportedProblemActions.submitReportProblem(params)),
    alertPush: (payload) => dispatch(alertActions.alertPush(payload)),
    alertDelete: () => dispatch(alertActions.alertDelete())
  })
}

export const ReportProblemDialog = withRouter(connect(null, mapDispatchToProps)(ReportProblemModal));
